import { loadState, saveState } from "shared/localstorage_helpers"
import { initialState } from "shared/components/connection_card/reducers/user"
import { storageIdentifier } from "shared/components/connection_card/utils.js"

export default function SignedOutConnectButton() {
  const connectButton = document.querySelector(".connect-button")
  const {url, applet_slug, sessionId} = JSON.parse(connectButton.dataset.connect)

  connectButton.addEventListener("click", () => {
    const localStorageId = storageIdentifier(applet_slug, sessionId)

    const persistedState = loadState(localStorageId) || { user: initialState }
    persistedState.user.authRequestedAt = new Date().toISOString()

    saveState({ user: initialState}, localStorageId)

    window.location.assign(url)
  }, { once: true })
}
