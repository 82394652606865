export function toggleAppletDetails() {
  const appletDetailsButton = document.querySelector("#applet-details-accordion-button")
  const appletDetailsWrapper = document.querySelector(".applet-details-wrapper")

  appletDetailsButton.addEventListener("click", () => {
    if (appletDetailsButton.innerText === "Fewer details") {
      appletDetailsButton.innerText = "More details"
      appletDetailsWrapper.classList.add("hide")
    } else {
      appletDetailsButton.innerText = "Fewer details"
      appletDetailsWrapper.classList.remove("hide")
    }
  })
}
